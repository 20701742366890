@import "../../variable.scss";

.bulk_receipt {
    &.content_area {
        background-color: $white;
        padding-left:0px !important;
    }
    .sub_title {
        font-size: 16px;
        font-weight: 400;
        color: $nevada; 
    }
    .width120 {
        width: 120px;
        float: left;
    }
    .to_separator {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        padding-top: 18px;
        margin: 0 8px;
        float: left;
    }
}